import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

const Terms = () => (
  <StyledTerms>
    <section>
      <h2>Uslovi kupovine</h2>
      <p>
        Prodavac posluje putem web trgovine na adresi internet stranice:
        <Link to="/"> https://velikeslike.com</Link>.
      </p>
      <p>
        Kupac je posjetioc ovih stranica koji popuni elektronsku narudžbu i
        pošalje je prodavcu.
      </p>
      <p>
        Kupcem se smatra svaka osoba koja elektronski naruči barem jedan
        proizvod, popuni tražene podatke i izvrši narudžbu.
      </p>
      <p>
        Kupovina se smatra zaključenom kada kupac dobije potvrdu narudžbe na
        mejl.
      </p>
    </section>
    <section>
      <h2>Cijena artikla</h2>
      <p>
        Sve cijene artikala su izražene u valuti konvertibilna marka. Cijene su
        za prikazanu sliku, a ako je višedjelna za cio komplet.
      </p>
      <p>
        <p>
          Slike koje isporučujemo su zategnute na drveni-blind ram, sa
          postavljenom zakačkom i spremne su za kačenje.
        </p>
        <b>
          Plaćanje se vrši prilikom preuzimanja (pouzećem). Dozvoljeno je
          otvaranje pošiljke prije plaćanja i preuzimanja.
        </b>
      </p>
    </section>
    <section>
      <h2>Isporuka robe</h2>
      <p>Osigurana je isporuka robe na prostoru cijele teritorije BiH.</p>
      <p>Uobičajeno vrijeme isporuke je 3-5 radnih dana od potvrde narudžbe.</p>
      <p>
        Robu koju prodajemo putem internet trgovine isporučujemo upakovanu,
        uključujući po potrebi dodatnu zaštitu od oštećenja i lomova u
        transportu.
      </p>
      <p>Roba se dostavlja putem brze pošte.</p>
      <p>Po slanju pošiljke možete zahtijevati KOD pošiljke za praćenje. </p>
      <p>
        Dostava za pošiljke 70 KM i više je besplatna, za pošiljke vrijednosti
        ispod 70 KM, dostava je 10 KM
      </p>
    </section>
    <section>
      <h2>Uslovi reklamacije</h2>
      <p>
        <b>
          Prodavac odgovara za eventualna oštećenja u transportu, ali je bitno
          da nas kupac kontaktira odmah pri preuzimanju pošiljke, odnosno da ne
          preuzima oštećenu pošiljku. U slučaju naknadne reklamacije, kupac će
          snositi troškove povrata robe.
        </b>
      </p>
    </section>
  </StyledTerms>
)

const StyledTerms = styled.div`
  background-color: #fff;
  padding: 20px 10px;
  position: relative;
  z-index: 20;
  section {
    margin: 0 auto;
    max-width: 1280px;
  }
  h2,
  p {
    color: #524448;
    font-weight: 300;
    letter-spacing: 0.1em;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
  }
  p {
    margin: 5px auto;
    max-width: 780px;
    font-size: 16px;
    text-align: justify;
  }
`

export default Terms
