export const calculatePrice = (width, height, count, pricePerSqMeter) => {
  let diameterPerCount = {
    width: width / count,
    height,
  }

  // calculate frame cost
  let diameter = (2 * (diameterPerCount.width + diameterPerCount.height)) / 100
  let frameCost = (diameter * 5 + 2.5) * count

  // calculate canvas cost
  let canvasCost =
    (((diameterPerCount.width + 7) * (diameterPerCount.height + 7) * count) /
      10000) *
    pricePerSqMeter

  const result = {
    frameCost,
    canvasCost,
    total: frameCost + canvasCost,
  }
  return result
}
