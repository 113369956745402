import React, { useState, useContext, useEffect } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { uuid } from "uuidv4"
import Modal from "react-modal"

import { CartContext } from "../context/cartContext"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Terms from "../components/terms"

import CartIcon from "../images/svg/cart.svg"
import InfoIcon from "../images/svg/info.svg"

import { calculatePrice } from "../utils/calculator"

const Product = ({ data }) => {
  const [price, setPrice] = useState(5)
  const [cart, setCart] = useContext(CartContext)
  const [size, setSize] = useState(
    `${data.sanityProduct.defaultProductVariant.sizes[0].width}x${data.sanityProduct.defaultProductVariant.sizes[0].height}`
  )
  const [canvas, setCanvas] = useState(true)
  const [isFramed, setIsFramed] = useState(true)

  const addToCart = () => {
    let id = uuid()
    const product = {
      id: id,
      image:
        data.sanityProduct.defaultProductVariant.images.asset.fluid.srcWebp,
      name: data.sanityProduct.title,
      price: price,
    }
    setCart(currentState => [...currentState, product])
  }

  const sizeSelected = e => {
    setSize(e.target.value)
  }

  let category = data.sanityProduct.categories[0].slug.current
  useEffect(() => {
    let canvasSize = size.split("x").map(num => parseInt(num, 10))
    let count = 1
    let pricePerSq = 40

    // Set price per sq meter
    if (category === "tapete") pricePerSq = 25
    else if (category === "jednodjelne-slike" || category === "dvodjelne-slike")
      pricePerSq = 70
    else if (category === "trodjelne-slike") pricePerSq = 65
    else if (
      category === "cetverodjelne-slike" ||
      category === "petodjelne-slike"
    )
      pricePerSq = 60
    else pricePerSq = 65

    // Set count
    if (category === "dvodjelne-slike") count = 2
    else if (category === "trodjelne-slike") count = 3
    else if (category === "cetverodjelne-slike") count = 4
    else if (category === "petodjelne-slike") count = 5
    else count = 1

    let total = calculatePrice(canvasSize[0], canvasSize[1], count, pricePerSq)

    setPrice(Math.ceil(total.total))
  }, [size, canvas, category, isFramed])

  return (
    <Layout>
      <SEO title={data.sanityProduct.title} />
      <ProductDetail>
        <img
          src={
            data.sanityProduct.defaultProductVariant.images.asset.fluid.srcWebp
          }
          alt={data.sanityProduct.title}
        />
        <div className="detail">
          <Link to={`/${data.sanityProduct.categories[0].slug.current}`}>
            {data.sanityProduct.categories[0].slug.current}
          </Link>
          <h1>{data.sanityProduct.title}</h1>
          <h2 className="price">{`${price}KM`}</h2>
          <h3>Izaberi veličinu:</h3>
          <select value={size} onChange={sizeSelected} className="sizes">
            {data.sanityProduct.defaultProductVariant.sizes.map(size => (
              <option
                key={`${size.width}x${size.height}`}
              >{`${size.width}x${size.height}`}</option>
            ))}
          </select>
          <div className="buttons">
            <button className="cart" onClick={addToCart}>
              <CartIcon />
              Dodaj u korpu
            </button>
          </div>
        </div>
      </ProductDetail>
      <Terms />
    </Layout>
  )
}

export default Product

const ProductDetail = styled.section`
  color: #505050;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  img {
    max-width: 600px;
    width: 100%;
  }
  .detail {
    h1,
    h2,
    h3,
    a,
    select,
    button {
      letter-spacing: 0.1em;
    }
    h1 {
      font-size: 32px;
      font-weight: 300;
    }
    h2 {
      font-size: 48px;
      font-weight: 400;
    }
    .sizes {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 30px;
    }
    .type {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      text-align: center;
    }
    .buttons {
      padding-top: 20px;
      button {
        font-weight: 300;
        margin: 10px 0;
      }
    }
    .cart {
      background-color: #ac6063;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      svg {
        fill: #fff;
        margin-right: 10px;
      }
      :hover {
        opacity: 0.9;
      }
    }
    .info {
      background-color: #74938d;
    }
    select {
      background-color: #fff;
      border: none;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
      color: #505050;
      cursor: pointer;
      font-size: 18px;
      font-weight: bold;
      padding: 20px 40px;
      :hover {
        background-color: #f9f9f9;
      }
    }
  }
`

export const productQuery = graphql`
  query product($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      title
      defaultProductVariant {
        sku
        sizes {
          height
          width
        }
        images {
          asset {
            fluid {
              srcWebp
            }
          }
        }
      }
      categories {
        slug {
          current
        }
        parents {
          slug {
            current
          }
        }
      }
    }
  }
`
